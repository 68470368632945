<template>
  <v-container fluid class="pl-12 pr-12">
    <v-layout column wrap>
      <v-row>
        <p class="font-weight-black pl-3" style="font-size:32px">
          {{ $t('title.agency.addApplicationForm') }}
        </p>
      </v-row>
      <ValidationForms ref="forms">
        <v-row class="mb-1">
          <input ref="input" type="file" @change="setPdf" accept=".pdf" />
        </v-row>
        <v-row>
          <p style="font-size:10px;">{{ $t('label.uploadLimitFileSize') }}</p>
        </v-row>
        <template v-if="images">
          <v-row class="mb-2">
            <v-col>
              <ValidationForm
                :rules="`required|max:${MaxLength.FormId}|pattern:formId`"
              >
                <v-text-field
                  v-model="applicationFormDetails.formId"
                  type="text"
                  :label="$t('label.formId')"
                  dense
                  hide-details
                ></v-text-field>
              </ValidationForm>
            </v-col>
            <v-col>
              <ValidationForm
                :rules="`required|max:${MaxLength.ApplicationFormName}`"
              >
                <v-text-field
                  v-model="applicationFormDetails.name"
                  type="text"
                  :label="$t('label.applicationFormName')"
                  dense
                  hide-details
                ></v-text-field>
              </ValidationForm>
            </v-col>
            <v-col>
              <ValidationForm rules="selectRequired">
                <v-select
                  v-model="applicationFormDetails.insuranceTypeId"
                  :label="$t('label.insuranceName')"
                  item-text="text"
                  item-value="value"
                  :items="insuranceTypeList"
                  dense
                  hide-details
                />
              </ValidationForm>
            </v-col>
            <v-col>
              <v-menu
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                width="100%"
                max-width="350px"
                v-model="menu"
              >
                <template v-slot:activator="{ on, attrs }">
                  <ValidationForm rules="required">
                    <v-text-field
                      v-model="applicationFormDetails.startDate"
                      :label="$t('label.applicationFormStartDate')"
                      prepend-icon="event"
                      readonly
                      v-on="on"
                      v-bind="attrs"
                      dense
                      hide-details
                    ></v-text-field>
                  </ValidationForm>
                </template>
                <v-date-picker
                  locale="jp-ja"
                  v-model="applicationFormDetails.startDate"
                  :day-format="date => new Date(date).getDate()"
                  color="calendar"
                  full-width
                  @change="menu = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
          <v-row>
            <ValidationForm
              :rules="`required|max:${MaxLength.ApplicationFormMessage}`"
            >
              <v-textarea
                rows="2"
                row-height="20"
                outlined
                clearable
                v-model="applicationFormDetails.confirmationMessage"
                :counter="MaxLength.ApplicationFormMessage"
                :value="applicationFormDetails.confirmationMessage"
                :label="$t('label.confirmationMessage')"
              ></v-textarea>
            </ValidationForm>
          </v-row>
          <v-row class="mb-3">
            <v-container
              id="scroll-target"
              class="overflow-y-auto mx-auto"
              style="max-height: 60vh; width: 100%; border: #ddd 3px solid; "
            >
              <template v-for="(image, index) in images">
                <v-row
                  v-if="image != null && image.length > 0"
                  class="ml-2"
                  v-scroll:#scroll-target="onScroll"
                  style="height:auto; max-width: 100%; width: 100%; display: block;"
                  :key="index"
                >
                  <v-img :src="image" contain :aspect-ratio="aspect" />
                </v-row>
              </template>
            </v-container>
          </v-row>
          <v-row>
            <v-btn
              class="font-weight-black mx-auto"
              align="center"
              width="30vw"
              max-width="170px"
              color="next"
              style="font-size:20px"
              dark
              @click="goNextPage"
              >{{ $t('button.next') }}</v-btn
            >
          </v-row>
        </template>
      </ValidationForms>
      <!-- ファイルアップロード時のエラー結果表示ダイアログ -->
      <SimpleDialog
        :maxWidth="500"
        :showDialog="showDialog"
        :title="DialogTitle"
        :confirmText="DialogText"
        :negativeButtonTitle="null"
        :positiveButtonTitle="$t('button.close')"
        :onClickNegativeButton="null"
        :onClickPositiveButton="closeDialog"
      />
      <ErrorDialog ref="errorDialog"></ErrorDialog>
    </v-layout>
  </v-container>
</template>

<script>
import ValidationForms from '@/components/organisms/common/ValidationForms';
import ValidationForm from '@/components/organisms/common/ValidationForm';
import SimpleDialog from '@/components/organisms/agency/SimpleDialog';
import ErrorDialog from '@/components/organisms/agency/ErrorDialog';
import { uploadLimitFileSize, MaxLength } from '@/lib/const';
import { getPdfImage, getPdfWidthAndHeight } from '@/lib/commonUtil';
import { mapActions } from 'vuex';
import {
  getApplicationFormStartDateList,
  getApplicationFormList,
} from '@/apis/agency/applicationForms';
import { getInsuranceTypeList } from '@/apis/agency/insuranceTypes';
import moment from 'moment';

export default {
  name: 'AddApplicationForm',
  components: {
    SimpleDialog,
    ErrorDialog,
    ValidationForms,
    ValidationForm,
  },
  data() {
    return {
      // 申込書の画像群
      images: null,
      showDialog: false,
      DialogText: null,
      DialogTitle: null,
      applicationFormPdf: null,
      applicationFormDetails: {
        formId: null,
        name: null,
        insuranceTypeId: null,
        startDate: null,
        confirmationMessage: null,
        width: null,
        height: null,
      },
      aspect: null,
      MaxLength: MaxLength,
      insuranceTypeList: [],
      menu: false,
    };
  },
  async mounted() {
    const insuranceTypeList = await getInsuranceTypeList();
    this.$set(
      this,
      'insuranceTypeList',
      insuranceTypeList.map(insuranceType => {
        return {
          text: insuranceType.name,
          value: insuranceType.id,
        };
      })
    );
  },
  methods: {
    ...mapActions('applicationForm', [
      'setApplicationFormPdf',
      'setApplicationFormDetails',
      'setApplicationFormImages',
    ]),
    // ...mapActions('validation', ['setStartDates']),
    ...mapActions('ui', ['setLoading']),
    setPdf(e) {
      const file = e.target.files[0];
      const reader = new FileReader();
      reader.onload = async v => {
        if (file.type === 'application/pdf') {
          if (file.size <= uploadLimitFileSize) {
            this.$set(this, 'applicationFormPdf', file);

            this.$set(this, 'images', await getPdfImage(v.target.result));

            const pdfWidthAndHeight = await getPdfWidthAndHeight(
              v.target.result
            );
            this.$set(
              this.applicationFormDetails,
              'width',
              Math.floor(pdfWidthAndHeight.width)
            );
            this.$set(
              this.applicationFormDetails,
              'height',
              Math.floor(pdfWidthAndHeight.height)
            );
            this.$set(
              this,
              'aspect',
              pdfWidthAndHeight.width / pdfWidthAndHeight.height
            );

            // 画像化した申込書はハイライト処理時に再利用するためストアに保持
            this.setApplicationFormImages(this.images);
          } else {
            this.$set(this, 'DialogTitle', this.$t('label.fileUploadError'));
            this.$set(
              this,
              'DialogText',
              this.$t('error.uploadAddApplicationFormLimitFileSizeOver')
            );
            this.$set(this, 'showDialog', true);

            // アップロードファイル情報削除
            e.target.value = '';
          }
        } else {
          this.$set(this, 'DialogTitle', this.$t('label.fileUploadError'));
          this.$set(this, 'DialogText', this.$t('error.invalidPdfFile'));
          this.$set(this, 'showDialog', true);
          e.target.value = '';
        }
      };
      reader.readAsDataURL(file);
    },
    onScroll(e) {
      this.offsetTop = e.target.scrollTop;
    },
    async goNextPage() {
      if (await this.$refs.forms.validate()) {
        // 申込書の帳票ID一覧取得
        const applicationFormList = await getApplicationFormList();
        const formIdList = applicationFormList.map(applicationForm => {
          return applicationForm.formId;
        });
        // 帳票IDの重複チェック
        if (formIdList.includes(this.applicationFormDetails.formId)) {
          this.$refs.errorDialog.open(
            this.$t('title.agency.registerFormIdError'),
            this.$t('error.formId', {
              formId: this.applicationFormDetails.formId,
            })
          );
          return;
        }

        // 申込書情報の保持
        this.setApplicationFormPdf(this.applicationFormPdf);
        this.setApplicationFormDetails(this.applicationFormDetails);
        this.$router.push('/register_application_form_printing_position');
      }
    },
    closeDialog() {
      this.showDialog = false;
    },
    // NOTE: UAT128対応、重複条件改善予定
    // async selectInsuranceType(insuranceTypeId) {
    //   this.setLoading(true);
    //   const startDateList = await getApplicationFormStartDateList(
    //     insuranceTypeId
    //   );
    //   let validateStartDates = [];
    //   for (let i = 0; i < startDateList.length; i++) {
    //     if (this.editId !== startDateList[i].id)
    //       validateStartDates.push(
    //         moment(startDateList[i].startDate).format('YYYY-MM-DD')
    //       );
    //   }
    //   this.setStartDates(validateStartDates);
    //   this.setLoading(false);
    // },
  },
};
</script>

<style>
.v-date-picker-table .v-btn__content {
  font-size: 25px !important;
}
</style>
