var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "pl-12 pr-12", attrs: { fluid: "" } },
    [
      _c(
        "v-layout",
        { attrs: { column: "", wrap: "" } },
        [
          _c("v-row", [
            _c(
              "p",
              {
                staticClass: "font-weight-black pl-3",
                staticStyle: { "font-size": "32px" }
              },
              [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.$t("title.agency.addApplicationForm")) +
                    "\n      "
                )
              ]
            )
          ]),
          _c(
            "ValidationForms",
            { ref: "forms" },
            [
              _c("v-row", { staticClass: "mb-1" }, [
                _c("input", {
                  ref: "input",
                  attrs: { type: "file", accept: ".pdf" },
                  on: { change: _vm.setPdf }
                })
              ]),
              _c("v-row", [
                _c("p", { staticStyle: { "font-size": "10px" } }, [
                  _vm._v(_vm._s(_vm.$t("label.uploadLimitFileSize")))
                ])
              ]),
              _vm.images
                ? [
                    _c(
                      "v-row",
                      { staticClass: "mb-2" },
                      [
                        _c(
                          "v-col",
                          [
                            _c(
                              "ValidationForm",
                              {
                                attrs: {
                                  rules:
                                    "required|max:" +
                                    _vm.MaxLength.FormId +
                                    "|pattern:formId"
                                }
                              },
                              [
                                _c("v-text-field", {
                                  attrs: {
                                    type: "text",
                                    label: _vm.$t("label.formId"),
                                    dense: "",
                                    "hide-details": ""
                                  },
                                  model: {
                                    value: _vm.applicationFormDetails.formId,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.applicationFormDetails,
                                        "formId",
                                        $$v
                                      )
                                    },
                                    expression: "applicationFormDetails.formId"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "v-col",
                          [
                            _c(
                              "ValidationForm",
                              {
                                attrs: {
                                  rules:
                                    "required|max:" +
                                    _vm.MaxLength.ApplicationFormName
                                }
                              },
                              [
                                _c("v-text-field", {
                                  attrs: {
                                    type: "text",
                                    label: _vm.$t("label.applicationFormName"),
                                    dense: "",
                                    "hide-details": ""
                                  },
                                  model: {
                                    value: _vm.applicationFormDetails.name,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.applicationFormDetails,
                                        "name",
                                        $$v
                                      )
                                    },
                                    expression: "applicationFormDetails.name"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "v-col",
                          [
                            _c(
                              "ValidationForm",
                              { attrs: { rules: "selectRequired" } },
                              [
                                _c("v-select", {
                                  attrs: {
                                    label: _vm.$t("label.insuranceName"),
                                    "item-text": "text",
                                    "item-value": "value",
                                    items: _vm.insuranceTypeList,
                                    dense: "",
                                    "hide-details": ""
                                  },
                                  model: {
                                    value:
                                      _vm.applicationFormDetails
                                        .insuranceTypeId,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.applicationFormDetails,
                                        "insuranceTypeId",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "applicationFormDetails.insuranceTypeId"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "v-col",
                          [
                            _c(
                              "v-menu",
                              {
                                attrs: {
                                  "close-on-content-click": false,
                                  transition: "scale-transition",
                                  "offset-y": "",
                                  width: "100%",
                                  "max-width": "350px"
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "activator",
                                      fn: function(ref) {
                                        var on = ref.on
                                        var attrs = ref.attrs
                                        return [
                                          _c(
                                            "ValidationForm",
                                            { attrs: { rules: "required" } },
                                            [
                                              _c(
                                                "v-text-field",
                                                _vm._g(
                                                  _vm._b(
                                                    {
                                                      attrs: {
                                                        label: _vm.$t(
                                                          "label.applicationFormStartDate"
                                                        ),
                                                        "prepend-icon": "event",
                                                        readonly: "",
                                                        dense: "",
                                                        "hide-details": ""
                                                      },
                                                      model: {
                                                        value:
                                                          _vm
                                                            .applicationFormDetails
                                                            .startDate,
                                                        callback: function(
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.applicationFormDetails,
                                                            "startDate",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "applicationFormDetails.startDate"
                                                      }
                                                    },
                                                    "v-text-field",
                                                    attrs,
                                                    false
                                                  ),
                                                  on
                                                )
                                              )
                                            ],
                                            1
                                          )
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  false,
                                  1681765629
                                ),
                                model: {
                                  value: _vm.menu,
                                  callback: function($$v) {
                                    _vm.menu = $$v
                                  },
                                  expression: "menu"
                                }
                              },
                              [
                                _c("v-date-picker", {
                                  attrs: {
                                    locale: "jp-ja",
                                    "day-format": function(date) {
                                      return new Date(date).getDate()
                                    },
                                    color: "calendar",
                                    "full-width": ""
                                  },
                                  on: {
                                    change: function($event) {
                                      _vm.menu = false
                                    }
                                  },
                                  model: {
                                    value: _vm.applicationFormDetails.startDate,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.applicationFormDetails,
                                        "startDate",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "applicationFormDetails.startDate"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "v-row",
                      [
                        _c(
                          "ValidationForm",
                          {
                            attrs: {
                              rules:
                                "required|max:" +
                                _vm.MaxLength.ApplicationFormMessage
                            }
                          },
                          [
                            _c("v-textarea", {
                              attrs: {
                                rows: "2",
                                "row-height": "20",
                                outlined: "",
                                clearable: "",
                                counter: _vm.MaxLength.ApplicationFormMessage,
                                value:
                                  _vm.applicationFormDetails
                                    .confirmationMessage,
                                label: _vm.$t("label.confirmationMessage")
                              },
                              model: {
                                value:
                                  _vm.applicationFormDetails
                                    .confirmationMessage,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.applicationFormDetails,
                                    "confirmationMessage",
                                    $$v
                                  )
                                },
                                expression:
                                  "applicationFormDetails.confirmationMessage"
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "v-row",
                      { staticClass: "mb-3" },
                      [
                        _c(
                          "v-container",
                          {
                            staticClass: "overflow-y-auto mx-auto",
                            staticStyle: {
                              "max-height": "60vh",
                              width: "100%",
                              border: "#ddd 3px solid"
                            },
                            attrs: { id: "scroll-target" }
                          },
                          [
                            _vm._l(_vm.images, function(image, index) {
                              return [
                                image != null && image.length > 0
                                  ? _c(
                                      "v-row",
                                      {
                                        directives: [
                                          {
                                            name: "scroll",
                                            rawName: "v-scroll:#scroll-target",
                                            value: _vm.onScroll,
                                            expression: "onScroll",
                                            arg: "#scroll-target"
                                          }
                                        ],
                                        key: index,
                                        staticClass: "ml-2",
                                        staticStyle: {
                                          height: "auto",
                                          "max-width": "100%",
                                          width: "100%",
                                          display: "block"
                                        }
                                      },
                                      [
                                        _c("v-img", {
                                          attrs: {
                                            src: image,
                                            contain: "",
                                            "aspect-ratio": _vm.aspect
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  : _vm._e()
                              ]
                            })
                          ],
                          2
                        )
                      ],
                      1
                    ),
                    _c(
                      "v-row",
                      [
                        _c(
                          "v-btn",
                          {
                            staticClass: "font-weight-black mx-auto",
                            staticStyle: { "font-size": "20px" },
                            attrs: {
                              align: "center",
                              width: "30vw",
                              "max-width": "170px",
                              color: "next",
                              dark: ""
                            },
                            on: { click: _vm.goNextPage }
                          },
                          [_vm._v(_vm._s(_vm.$t("button.next")))]
                        )
                      ],
                      1
                    )
                  ]
                : _vm._e()
            ],
            2
          ),
          _c("SimpleDialog", {
            attrs: {
              maxWidth: 500,
              showDialog: _vm.showDialog,
              title: _vm.DialogTitle,
              confirmText: _vm.DialogText,
              negativeButtonTitle: null,
              positiveButtonTitle: _vm.$t("button.close"),
              onClickNegativeButton: null,
              onClickPositiveButton: _vm.closeDialog
            }
          }),
          _c("ErrorDialog", { ref: "errorDialog" })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }