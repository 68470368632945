import axios from '@/apis/axios';
import { getAccessToken } from '@/lib/api';

/**
 * 保険種別一覧取得
 */
export const getInsuranceTypeList = async () => {
  const accessToken = await getAccessToken();

  const response = await axios
    .get('/agencies/insurance_types/', {
      headers: { 'access-token': accessToken },
    })
    .catch(error => {
      throw error.response.data;
    });

  return response.data;
};

/**
 * 保険種別登録
 */
export const postInsuranceType = async insuranceType => {
  const accessToken = await getAccessToken();

  const response = await axios
    .post(
      '/agencies/insurance_types/',
      { ...insuranceType },
      {
        headers: { 'access-token': accessToken },
      }
    )
    .catch(error => {
      throw error.response.data;
    });

  return response.data;
};

/**
 * 保険種別修正
 */
export const putInsuranceType = async (insuranceTypeId, insuranceType) => {
  const accessToken = await getAccessToken();

  const response = await axios
    .put(
      `/agencies/insurance_types/${insuranceTypeId}`,
      { ...insuranceType },
      {
        headers: { 'access-token': accessToken },
      }
    )
    .catch(error => {
      throw error.response.data;
    });

  return response.data;
};
