import { validation } from '@/lib/validation';
import { extend, configure } from 'vee-validate';
import { MaxLength } from '@/lib/const';
import { length, required, max, min, numeric } from 'vee-validate/dist/rules';
import i18n from '@/plugins/i18n';
import store from '@/store';

configure({
  // バリデーションメッセージの指定
  defaultMessage: (_, values) => i18n.t(`validation.${values._rule_}`, values),
});

// 必須入力
extend('required', {
  ...required,
});

// 必須選択
extend('selectRequired', {
  ...required,
});

// 半角数字のみ
extend('numeric', {
  ...numeric,
});

// 半角スペースなし
extend('noSpace', {
  validate: value => validation.noSpace(value),
});

// 文字数指定
extend('length', {
  ...length,
});

// 最大文字長
extend('max', {
  ...max,
});

// 最小文字長
extend('min', {
  ...min,
});

// 部担コード入力チェック
extend('salesCodeCheck', {
  validate: value => validation.salesCodeCheck(value),
});

// 代理店コード入力チェック
extend('agencyCodeCheck', {
  validate: value => validation.agencyCodeCheck(value),
});

// 文字数 (電話番号)
extend('telNumberLength', {
  validate: value => validation.telNumberLength(value),
});

// 正規表現
extend('pattern', {
  validate: (value, args) => validation.pattern(value, args.pattern),
  params: ['pattern'],
});

// 拡張子確認
extend('checkExt', {
  message: (value, args) => {
    let ext = Array.isArray(args.ext) ? args.ext.join('、') : args.ext;
    return i18n.t(`validation.checkExt`, { ext: ext });
  },
  validate: (files, args) => {
    let ext = Array.isArray(args.ext) ? args.ext.join('|') : args.ext;
    let regex = new RegExp('.(' + ext + ')$', 'i');
    if (Array.isArray(files)) {
      return files.every(function(file) {
        return regex.test(file.name);
      });
    }
    return regex.test(files.name);
  },
  params: ['ext'],
});

// ファイルサイズ確認
extend('maxSize', {
  message: (value, args) => {
    return i18n.t(`validation.maxsize`, { size: args.size });
  },
  validate: (files, args) => {
    return files[0].size <= args.size;
  },
  params: ['size'],
});

// 適用開始日重複確認
extend('startDateCheck', {
  message: (value, args) => {
    return i18n.t(`validation.startDate`, { date: args._value_ });
  },
  validate: value => {
    let startDates = store.getters['validation/startDates'];
    return !startDates.includes(value);
  },
});

// 半角数字存在チェック
extend('requiredHalfNumber', {
  validate: value => validation.requiredHalfNumber(value),
});

// 半角大文字存在チェック
extend('requiredHalfUppercase', {
  validate: value => validation.requiredHalfUppercase(value),
});

// 半角小文字存在チェック
extend('requiredHalfLowercase', {
  validate: value => validation.requiredHalfLowercase(value),
});

// 必須タグチェック:{fullNameKanji}
extend('requiredFullNameKanji', {
  validate: value => validation.requiredFullNameKanji(value),
});

// 必須タグチェック:{insuranceName}
extend('requiredInsuranceName', {
  validate: value => validation.requiredInsuranceName(value),
});

// 必須タグチェック:{startDate}
extend('requiredStartDate', {
  validate: value => validation.requiredStartDate(value),
});

// 必須タグチェック:{lastAccessibleDay}
extend('requiredLastAccessibleDay', {
  validate: value => validation.requiredLastAccessibleDay(value),
});

// 必須タグチェック:{contractUrl}
extend('requiredContractUrl', {
  validate: value => validation.requiredContractUrl(value),
});

// 必須タグチェック:{agencyName}
extend('requiredAgencyName', {
  validate: value => validation.requiredAgencyName(value),
});

// 必須タグチェック:{chargePersonName}
extend('requiredChargePersonName', {
  validate: value => validation.requiredChargePersonName(value),
});

// 必須タグチェック:{agencyPhoneNumber}
extend('requiredAgencyPhoneNumber', {
  validate: value => validation.requiredAgencyPhoneNumber(value),
});

// 必須タグチェック:{officialWebSiteUrl}
extend('requiredOfficialWebSiteUrl', {
  validate: value => validation.requiredOfficialWebSiteUrl(value),
});

// 必須タグチェック:{officialLineUrl}
extend('requiredOfficialLineUrl', {
  validate: value => validation.requiredOfficialLineUrl(value),
});

// 必須タグチェック:{modificationContent}
extend('requiredModificationContent', {
  validate: value => validation.requiredModificationContent(value),
});

// 使用不可タグチェック:{modificationContent}
extend('unusableModificationContent', {
  validate: value => validation.unusableModificationContent(value),
});

// ファイル名重複確認
extend('checkFileNameExists', {
  message: () => {
    return i18n.t(`validation.checkFileNameExists`);
  },
  validate: (file, args) => {
    return args.name.indexOf(file[0].name) === -1;
  },
  params: ['name'],
});

// 最大ファイル名文字数確認
extend('maxFileName', {
  message: () => {
    return i18n.t(`validation.maxFileName`, {
      length: MaxLength.FileName,
    });
  },
  validate: file => {
    return file[0].name.length <= MaxLength.FileName;
  },
});
